import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  padding: 0 20px 0 10px;
  min-height: 121px;
  display: flex;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 149px;
  }

  & > :nth-child(1) {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  & > :nth-child(2) {
    flex: 1;
    margin-left: 130px;
    text-align: right;

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-left: 170px;
      margin-right: 170px;
      text-align: center;
    }
  }
`

const Hero = styled.span`
  width: 200px;
  height: 186px;
  transform: translate(-14px, 52px);

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    transform: translate(-0px, 34px);
  }

  & > * {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain !important;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Child = styled.div``

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 25px;
    line-height: 30px;
  }
`

const Description = styled.p`
  font-style: normal;
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
  }
`

interface QuoteHeaderProps {
  title: React.ReactNode
  description: React.ReactNode
}

const QuoteHeader: React.FC<QuoteHeaderProps> = ({ title, description }) => (
  <Container className="QuoteHeader">
    <Hero>
      <StaticImage
        src="../../static/images/HeroPersonDesktop.png"
        alt=""
        width={155}
        height={186}
        placeholder="blurred"
        quality={100}
      />
    </Hero>
    <Content>
      <Child>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Child>
    </Content>
  </Container>
)

export default QuoteHeader
