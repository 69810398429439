import styled from 'styled-components'

const QuoteSuccessContainer = styled.div`
  background-color: #fff;
  flex: 1;
  padding: 0 0 20px 0;

  & > *[class^='QuoteSuccessCard'] {
    max-width: min(576px, calc(100% - 2 * 13px));
    margin: 23px auto 0 auto;
  }
`

export default QuoteSuccessContainer
